<template>
  <div class="vertical-spaces">
    <div class="d-flex align-items-center justify-content-end px-2 mt-3">
      <ion-button v-if="get(user, 'is_email_verified', true)" class="button" @click="createSocialSpace">
        Create social space
      </ion-button>
      <VerificationButton actionName="Create social space" v-else />
    </div>
    <div class="d-flex justify-content-between mx-3 mt-4">
      <div class="d-flex">
        <ion-button class="back-button" @click="back">
          <ion-icon class="icon" :icon="arrowBackOutline" />
        </ion-button>
      </div>
      <div class="title text-black mt-1 mx-2">Social Spaces I Joined</div>
      <div></div>
    </div>
    <horizontal-joined-social-spaces
      :space="space" 
      :unread="unreadCounts" 
      :nextPage="hasMoreSpaces ? socialSpaceNextPage : null" 
      @isactive="activeSocialSpace"
    />
  </div>

  <div>
    <ion-button
      fill="clear"
      color="black"
      class="title-btn text-black ml-n4"
      :class="isMobSmallScreen ? 'mt-2' : 'mt-4'"
      @click="openPage"
    >
      {{ get(space[activeIndex], 'name', '') }}
    </ion-button>
  </div>

  <div class="mb-1">
    <small v-if="get(space[activeIndex], 'user.username', '')">
      Owned by
      <router-link
        :to="{ name: 'profile', params: { username: get(space[activeIndex], 'user.username', '') } }"
        class="text-primary"
        >@{{ get(space[activeIndex], 'user.username', '') }}</router-link
      >
    </small>
  </div>

  <div v-if="get(space[activeIndex], 'description', '')" class="mb-1">
    {{ truncateText(stripHtmlTags(get(space[activeIndex], 'description', '')), 300) }}
  </div>

  <div class="mb-1">
    <router-link
      v-if="get(space[activeIndex], 'slug', '')"
      :to="{ name: 'social-space-details', params: { slug: get(space[activeIndex], 'slug', '') } }"
      class="text-primary"
      ><small>Visit "{{ get(space[activeIndex], 'name', '') }}"</small></router-link
    >
  </div>

  <div class="sub-title my-2">Chatrooms</div>

  <div v-if="!isEmpty(rooms)">
    <ChatroomCard
      v-for="room in rooms"
      :key="room.id"
      :class="isMobSmallScreen ? 'set-margin' : 'mt-2'"
      :chatroom="room"
      :muted="get(muted, `${room.id}.is_muted`) || false"
      :member-count="get(counts, `${room.id}.member-count`) || 0"
      :status="get(counts, `${room.id}.status`) || false"
      :lastmsg="get(counts, `${room.id}.last_msg_time`) || null"
      :unreadchat="get(unreadchat, `${room.id}.unread_count`) || 0"
    />

    <div class="d-flex justify-content-center clickable-item-hov">
      <ChLoading size="sm" v-if="isloading" class="spinner" />
      <p v-else v-show="paging.next" @click="nextPage">Load More</p>
    </div>
  </div>
  <div v-else class="no-data">No Chatrooms here yet</div>
</template>

<script lang="ts" setup>
import { chatboxEllipses, peopleOutline, arrowBackOutline, caretBack, caretDown } from 'ionicons/icons';
import ChatroomCard from '@/shared/components/storage/ChatroomCard.vue';
import { stripHtmlTags } from '@/shared/utils/string';
import HorizontalJoinedSocialSpaces from '@/shared/pages/social-spaces/components/HorizontalJoinedSocialSpaces.vue';
import { truncateText } from '@/shared/utils/string';

import VerificationButton from '@/shared/components/VerificationButton.vue';
import { authStore } from '@/shared/pinia-store/auth';
const { user } = authStore();

const activeIndex = ref(0);
const router = useRouter();

const createSocialSpace = () => {
  const router = useRouter();
  const href = router.resolve({ name: 'create-social-space' }).href;
  location.assign(href);
};

const props = defineProps({
  space: {
    type: Object,
    default: () => ({}),
  },
  counts: {
    type: Object,
  },
  rooms: {
    type: Array,
  },
  unreadCounts: {
    type: Object,
  },
  unreadchat: {
    type: Object,
  },
  paging: {
    type: Object,
  },
  isloading: {
    type: Boolean,
  },
  muted: {
    type: Object,
  },
  hasMoreSpaces: {
    type: Boolean,
    default: false,
  },
});

const rooms = toRef(props, 'rooms') as any;
const space = toRef(props, 'space');
const emits = defineEmits(['done', 'loadmore', 'loadMoreSpaces']);
const back = () => {
  router.go(-1);
};

const socialSpaceNextPage = () => {
  emits('loadMoreSpaces');
}

const nextPage = () => {
  emits('loadmore');
};

const { width: windowWidth } = useWindowSize();

const isMobSmallScreen = computed(() => {
  return windowWidth.value <= 500;
});

const openPage = () => {
  router.push({ name: 'social-space-details', params: { slug: get(space.value[activeIndex.value], 'slug', '') } });
};

const activeSocialSpace = (index: any) => {
  activeIndex.value = index;
  emits('done', activeIndex.value);
};
</script>

<style lang="sass" scoped>
.button
  text-transform: none !important
  --border-radius: 6px !important
.set-user-margin
  margin-top: -7px
  color: var(--ion-color-primary)
  @media(max-width: 500px)
    margin-top: -10px
.sub-title
  font-weight: bold
  font-size: 18px
.set-margin
  margin-top: -5px
.vertical-spaces
  background: #c6c8d6

.back-button
  --background: #8168b7
  --border-radius: 10px
  height: 34px
.icon
  font-size: 14px
.title
  font-size: 24px
  font-weight: bold
.title-btn
  font-size: 22px
  font-weight: bold
</style>
