<template>
  <ion-page class="page bg-transparent">
    <header-loading v-if="isloadingData" />
    <vertical-social-spaces-users-joined
      v-else
      :space="filteredArray"
      :unread-counts="spaceUnreadCounts"
      :counts="counts"
      :rooms="rooms"
      :paging="chatpaging"
      :load="isloading"
      :muted="mutedStatus"
      :unreadchat="chatUnreadCounts"
      @done="fetchactiveIndex"
      @loadmore="requestLoadMore"
      @loadMoreSpaces="requestLoadMoreSpaces"
      :hasMoreSpaces="!!spacesPaging?.next"
    />
  </ion-page>
</template>

<script lang="ts" setup>
import HeaderLoading from './HeaderLoading.vue';
import VerticalSocialSpacesUsersJoined from '@/shared/components/VerticalSocialSpacesUsersJoined.vue';
import { getNextPage, getUserStoryWorld, getWorldsUnreadCounts } from '@/shared/actions/worlds';

import {
  getChatroomCounts,
  getchatUnreadCounts,
  getJoinedChatrooms,
  getUnmuteAndMuteNotificationStatus,
} from '@/shared/actions/socialSpaceChatroom';
import type { Paging } from '@/shared/types/static-types';
import { authStore } from '@/shared/pinia-store/auth';

const { user: loggedInUser } = authStore();
const userSpaces = ref([]) as any;
const rooms = ref([]) as any;
const activeIndex = ref(0);
const counts = ref({});
const mutedStatus = ref({});
const spaceUnreadCounts = ref({});
const chatUnreadCounts = ref({});
const filteredArray = ref([]) as any;
const unreadCountsArray = ref([]) as any;
const isloading = ref(false);
const isloadingData = ref(false);
const spacesPaging = ref({
  next: null,
  previous: null,
  count: 0,
  page: 1,
}) as Ref<{ next: string | null; previous: string | null; count: number; page: number }>;
const chatpaging = ref({
  next: null,
  previous: null,
  count: 0,
  page: 1,
}) as Ref<{ next: string | null; previous: string | null; count: number; page: number }>;

const userSocialSpaces = async () => {
  isloadingData.value = true;
  const resp = await getUserStoryWorld(loggedInUser.value.id, 'socialspace', 1);
  spacesPaging.value = resp;
  userSpaces.value = resp.results;
  await fetchSpaceUnreadCounts();

  for (const obj of userSpaces.value) {
    const unreadCount = spaceUnreadCounts.value[obj.id]?.worldunread_msgs_count || 0;
    if (unreadCount > 0) {
      unreadCountsArray.value.push(obj);
    } else {
      filteredArray.value.push(obj);
    }
  }
  filteredArray.value.unshift(...unreadCountsArray.value);

  fetchjoinedChatrooms();
  isloadingData.value = false;
};

const fetchSpaceUnreadCounts = async () => {
  spaceUnreadCounts.value = {};
  const resp = await getWorldsUnreadCounts(map(userSpaces.value, 'id'));
  spaceUnreadCounts.value = keyBy(resp, 'world_id');
};

const fetchchatUnreadCounts = async () => {
  chatUnreadCounts.value = {};
  const resp = await getchatUnreadCounts(map(rooms.value, 'id'));
  chatUnreadCounts.value = keyBy(resp, 'room');
};

const fetchjoinedChatrooms = async () => {
  const { results, ...paging } = await getJoinedChatrooms(get(filteredArray.value[activeIndex.value], 'id'), 1);
  rooms.value = results;
  chatpaging.value = paging;
  fetchchatroomCounts();
  fetchchatUnreadCounts();
  fetchMutedAndUmuteStatus();
};
const requestLoadMore = async () => {
  if (isloading.value) return;
  try {
    isloading.value = true;
    const { results, ...paging } = await getNextPage(chatpaging.value as Paging);
    rooms.value = rooms.value.concat(results);
    chatpaging.value = paging;
  } catch (e) {
  } finally {
    isloading.value = false;
  }
};

const requestLoadMoreSpaces = async () => {
  if (isloading.value) return;
  try {
    isloading.value = true;
    const { results, ...paging } = await getNextPage(spacesPaging.value as Paging);
    filteredArray.value = [...filteredArray.value, ...results];
    spacesPaging.value = paging;
  } catch (e) {
  } finally {
    isloading.value = false;
  }
};

const fetchchatroomCounts = async () => {
  const resp = await getChatroomCounts(map(rooms.value, 'id') as string[]);
  counts.value = keyBy(resp, 'id');
};
const fetchactiveIndex = (index: any) => {
  activeIndex.value = index;
  fetchjoinedChatrooms();
};

const fetchMutedAndUmuteStatus = async () => {
  const resp = await getUnmuteAndMuteNotificationStatus(map(rooms.value, 'id') as string[]);
  mutedStatus.value = keyBy(resp, 'room');
};

onMounted(async () => {
  userSocialSpaces();
});
</script>

<style lang="sass" scoped>
.title
  font-size: 20px
  font-weight: bold
</style>
